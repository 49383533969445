import { createContext } from 'react';
import * as waxjs from '@waxio/waxjs/dist';

export const initWaxJs = (userAccount = undefined, pubKeys = undefined) => new waxjs.WaxJS({
  rpcEndpoint: process.env.REACT_APP_WAX_CHAIN_URL || 'https://api.waxnet.io',
  waxSigningURL: process.env.REACT_APP_WAX_SIGNING_URL || undefined,
  waxAutoSigningURL: process.env.REACT_APP_WAX_AUTOSIGNING_URL || undefined,
  tryAutoLogin: false,
  userAccount,
  pubKeys,
});

// eslint-disable-next-line
export const AppContext = createContext<any>(initWaxJs);
