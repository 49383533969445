import { useContext } from 'react';
import { useQuery } from 'react-query';

import { getProfile } from 'services/ProfileService';

import { Profile } from 'model/Profile';
import { AppContext } from 'provider/AppContext';

const useProfile = () => {
  const wax = useContext(AppContext);

  const {
    isLoading,
    error,
    data: profile,
    refetch,
  } = useQuery<Profile, Error>('profile', () => getProfile(wax));

  return {
    isLoading,
    error,
    profile: profile || {
      name: '',
      avatar: '',
      frame: '',
    } as Profile,
    refetch,
  };
};

export default useProfile;
