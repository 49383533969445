import * as waxjs from '@waxio/waxjs/dist';

import { triggerNotification } from 'components/notification/Notification';

import { axiosInstanceBackendAPI } from 'helpers/AxiosHelper';

import { Profile } from 'model/Profile';

export const getProfile = (wax: waxjs.WaxJS): Promise<Profile> => (
  wax.userAccount
    ? axiosInstanceBackendAPI
      .get('/v1/profile', { withCredentials: true })
      .then((response) => response.data)
      .catch((error) => triggerNotification({ message: error.message }))
    : Promise.resolve()
);

export const saveProfile = (profile: Profile) => (
  axiosInstanceBackendAPI.post('/v1/profile', {
    ...profile,
  }, { withCredentials: true })
);
