import { signUalDummyTransaction } from 'helpers/ChainHelper';

import { CONTRACT_NAME } from 'constants/Constants';

import { axiosInstanceBackendAPI } from 'helpers/AxiosHelper';
import { User } from 'model/User';
import { LoginResponseDTO } from 'model/LoginResponseDTO';

export const login = async ({
  activeUser,
}: {
  activeUser: User;
}): Promise<LoginResponseDTO | null> => {
  const { accountName, requestPermission } = activeUser;

  const key = await axiosInstanceBackendAPI
    .get(`/key/${accountName}`)
    .then((response) => response.data);

  if (!key) return null;

  const actions = [
    {
      account: CONTRACT_NAME,
      name: 'login',
      authorization: [
        {
          actor: accountName,
          permission: requestPermission,
        },
      ],
      data: {
        user: accountName,
        key,
      },
    },
  ];

  const tx = await signUalDummyTransaction(activeUser, actions);

  const response = await axiosInstanceBackendAPI.post(
    '/login',
    {
      accountName,
      serializedTransaction: tx.transaction.serializedTransaction,
      signatures: tx.transaction.signatures,
    },
    { withCredentials: true },
  );

  if (response.status !== 200 || !response) return null;

  return {
    isFirstLogin: response.data.isFirstLogin,
    user: response.data.user,
  };
};

export const isActiveSession = () => (
  axiosInstanceBackendAPI
    .get('/session', { withCredentials: true })
    .then((response) => (!!response.data?.accountName))
    .catch(() => false)
);
