import React, { useEffect, useState } from 'react';
import { axiosInstanceBackendAPI } from 'helpers/AxiosHelper';
import { AppContext, initWaxJs } from './AppContext';

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [wax, setWax] = useState(initWaxJs);

  useEffect(() => {
    if (!wax?.user) {
      axiosInstanceBackendAPI
        .get('/session', { withCredentials: true })
        .then((response) => setWax(initWaxJs(
          response.data?.accountName,
          response.data?.publicKeys,
        )))
        // eslint-disable-next-line no-console
        .catch(() => { /* */ });
    }
  }, []);

  return (
    <AppContext.Provider value={wax}>
      {children}
    </AppContext.Provider>
  );
};
