import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AppContext } from 'provider/AppContext';

import { tosToAccept } from 'services/TOSService';

import { TOS } from 'model/TOS';

const useTOS = () => {
  const wax = useContext(AppContext);

  const {
    isLoading,
    error,
    data,
    refetch,
  } = useQuery<TOS | null, Error>('tos', () => tosToAccept(wax));

  return {
    isLoading,
    error,
    tos: data || null,
    refetch,
  };
};

export default useTOS;
