import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from 'provider/AppContext';
import ScreenLoader from 'components/screenLoader/ScreenLoader';
import useProfile from 'hooks/useProfile';
import useSession from 'hooks/useSession';

const RequireAuth = ({
  checkTemp,
  children,
}: {
  checkTemp?: boolean,
  children: JSX.Element
}) => {
  const wax = useContext(AppContext);
  const { profile, isLoading: profileLoading } = useProfile();
  const { isAuthenticated, loading: sessionLoading } = useSession();

  const currentLocation = new URL(window.location.href);
  const encodedLocation = encodeURIComponent(currentLocation.href.replace(currentLocation.origin, ''));
  const redirectLocation = `/?return_to=${encodedLocation}`;

  if (!sessionLoading && !isAuthenticated) return <Navigate to={redirectLocation} />;
  if (checkTemp && profile?.isTemp) return <Navigate to={redirectLocation} />;

  if (profileLoading || sessionLoading) return <ScreenLoader />;
  if (!wax && !profileLoading) return <ScreenLoader />;
  if (!profile?.account && wax?.user === undefined) return <ScreenLoader />;

  return (!wax?.user) ? <Navigate to={redirectLocation} /> : children;
};

export default RequireAuth;

RequireAuth.defaultProps = {
  checkTemp: false,
};
